import React from 'react'
import MediaQuery from "react-responsive";
import future_in_the_past01 from '../assets/images/future_in_the_past/2016_2018/01.jpg'
import future_in_the_past02 from '../assets/images/future_in_the_past/2016_2018/02.jpg'
import future_in_the_past03 from '../assets/images/future_in_the_past/2016_2018/03.jpg'
import future_in_the_past04 from '../assets/images/future_in_the_past/2016_2018/04.jpg'
import future_in_the_past05 from '../assets/images/future_in_the_past/2016_2018/05.jpg'
import future_in_the_past06 from '../assets/images/future_in_the_past/2016_2018/06.jpg'
import future_in_the_past07 from '../assets/images/future_in_the_past/2016_2018/07.jpg'
import future_in_the_past08 from '../assets/images/future_in_the_past/2016_2018/08.jpg'
import future_in_the_past09 from '../assets/images/future_in_the_past/2016_2018/09.jpg'
import future_in_the_past10 from '../assets/images/future_in_the_past/2016_2018/10.jpg'
import future_in_the_past11 from '../assets/images/future_in_the_past/2016_2018/11.jpg'
import future_in_the_past12 from '../assets/images/future_in_the_past/2016_2018/12.jpg'
import future_in_the_past13 from '../assets/images/future_in_the_past/2016_2018/13.jpg'
import future_in_the_past14 from '../assets/images/future_in_the_past/2016_2018/14.jpg'
import future_in_the_past15 from '../assets/images/future_in_the_past/2016_2018/15.jpg'
import future_in_the_past16 from '../assets/images/future_in_the_past/2016_2018/16.jpg'
import future_in_the_past17 from '../assets/images/future_in_the_past/2016_2018/17.jpg'
import future_in_the_past18 from '../assets/images/future_in_the_past/2016_2018/18.jpg'
import future_in_the_past19 from '../assets/images/future_in_the_past/2016_2018/19.jpg'
import future_in_the_past20 from '../assets/images/future_in_the_past/2016_2018/20.jpg'
import future_in_the_past21 from '../assets/images/future_in_the_past/2016_2018/21.jpg'
import thumb01 from '../assets/images/future_in_the_past/2016_2018/01.jpg'
import thumb02 from '../assets/images/future_in_the_past/2016_2018/02.jpg'
import thumb03 from '../assets/images/future_in_the_past/2016_2018/03.jpg'
import thumb04 from '../assets/images/future_in_the_past/2016_2018/04.jpg'
import thumb05 from '../assets/images/future_in_the_past/2016_2018/05.jpg'
import thumb06 from '../assets/images/future_in_the_past/2016_2018/06.jpg'
import thumb07 from '../assets/images/future_in_the_past/2016_2018/07.jpg'
import thumb08 from '../assets/images/future_in_the_past/2016_2018/08.jpg'
import thumb09 from '../assets/images/future_in_the_past/2016_2018/09.jpg'
import thumb10 from '../assets/images/future_in_the_past/2016_2018/10.jpg'
import thumb11 from '../assets/images/future_in_the_past/2016_2018/11.jpg'
import thumb12 from '../assets/images/future_in_the_past/2016_2018/12.jpg'
import thumb13 from '../assets/images/future_in_the_past/2016_2018/13.jpg'
import thumb14 from '../assets/images/future_in_the_past/2016_2018/14.jpg'
import thumb15 from '../assets/images/future_in_the_past/2016_2018/15.jpg'
import thumb16 from '../assets/images/future_in_the_past/2016_2018/16.jpg'
import thumb17 from '../assets/images/future_in_the_past/2016_2018/17.jpg'
import thumb18 from '../assets/images/future_in_the_past/2016_2018/18.jpg'
import thumb19 from '../assets/images/future_in_the_past/2016_2018/19.jpg'
import thumb20 from '../assets/images/future_in_the_past/2016_2018/20.jpg'
import thumb21 from '../assets/images/future_in_the_past/2016_2018/21.jpg'
import pcthumb01 from '../assets/images/future_in_the_past/2016_2018/thumbnail/01.jpg'
import pcthumb02 from '../assets/images/future_in_the_past/2016_2018/thumbnail/02.jpg'
import pcthumb03 from '../assets/images/future_in_the_past/2016_2018/thumbnail/03.jpg'
import pcthumb04 from '../assets/images/future_in_the_past/2016_2018/thumbnail/04.jpg'
import pcthumb05 from '../assets/images/future_in_the_past/2016_2018/thumbnail/05.jpg'
import pcthumb06 from '../assets/images/future_in_the_past/2016_2018/thumbnail/06.jpg'
import pcthumb07 from '../assets/images/future_in_the_past/2016_2018/thumbnail/07.jpg'
import pcthumb08 from '../assets/images/future_in_the_past/2016_2018/thumbnail/08.jpg'
import pcthumb09 from '../assets/images/future_in_the_past/2016_2018/thumbnail/09.jpg'
import pcthumb10 from '../assets/images/future_in_the_past/2016_2018/thumbnail/10.jpg'
import pcthumb11 from '../assets/images/future_in_the_past/2016_2018/thumbnail/11.jpg'
import pcthumb12 from '../assets/images/future_in_the_past/2016_2018/thumbnail/12.jpg'
import pcthumb13 from '../assets/images/future_in_the_past/2016_2018/thumbnail/13.jpg'
import pcthumb14 from '../assets/images/future_in_the_past/2016_2018/thumbnail/14.jpg'
import pcthumb15 from '../assets/images/future_in_the_past/2016_2018/thumbnail/15.jpg'
import pcthumb16 from '../assets/images/future_in_the_past/2016_2018/thumbnail/16.jpg'
import pcthumb17 from '../assets/images/future_in_the_past/2016_2018/thumbnail/17.jpg'
import pcthumb18 from '../assets/images/future_in_the_past/2016_2018/thumbnail/18.jpg'
import pcthumb19 from '../assets/images/future_in_the_past/2016_2018/thumbnail/19.jpg'
import pcthumb20 from '../assets/images/future_in_the_past/2016_2018/thumbnail/20.jpg'
import pcthumb21 from '../assets/images/future_in_the_past/2016_2018/thumbnail/21.jpg'
import Gallery from '../components/Gallery'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Exhibitionnav from '../components/Exhibitionnav'

const DEFAULT_IMAGES = [
  {
    id: '1',
    source: future_in_the_past01,
    thumbnail: pcthumb01,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '2',
    source: future_in_the_past02,
    thumbnail: pcthumb02,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '3',
    source: future_in_the_past03,
    thumbnail: pcthumb03,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '4',
    source: future_in_the_past04,
    thumbnail: pcthumb04,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '5',
    source: future_in_the_past05,
    thumbnail: pcthumb05,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '6',
    source: future_in_the_past06,
    thumbnail: pcthumb06,
    caption: 'Photo 6',
    description: '',
  },
  {
    id: '7',
    source: future_in_the_past07,
    thumbnail: pcthumb07,
    caption: 'Photo 7',
    description: '',
  },
  {
    id: '8',
    source: future_in_the_past08,
    thumbnail: pcthumb08,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '9',
    source: future_in_the_past09,
    thumbnail: pcthumb09,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '10',
    source: future_in_the_past10,
    thumbnail: pcthumb10,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '11',
    source: future_in_the_past11,
    thumbnail: pcthumb11,
    caption: 'Photo 11',
    description: '',
  },
  {
    id: '12',
    source: future_in_the_past12,
    thumbnail: pcthumb12,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '13',
    source: future_in_the_past13,
    thumbnail: pcthumb13,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '14',
    source: future_in_the_past14,
    thumbnail: pcthumb14,
    caption: 'Photo 14',
    description: '',
  },
  {
    id: '15',
    source: future_in_the_past15,
    thumbnail: pcthumb15,
    caption: 'Photo 15',
    description: '',
  },
  {
    id: '16',
    source: future_in_the_past16,
    thumbnail: pcthumb16,
    caption: 'Photo 16',
    description: '',
  },
  {
    id: '17',
    source: future_in_the_past17,
    thumbnail: pcthumb17,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '18',
    source: future_in_the_past18,
    thumbnail: pcthumb18,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '19',
    source: future_in_the_past19,
    thumbnail: pcthumb19,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '20',
    source: future_in_the_past20,
    thumbnail: pcthumb20,
    caption: 'Photo 7',
    description: '',
  },
  {
    id: '21',
    source: future_in_the_past21,
    thumbnail: pcthumb21,
    caption: 'Photo 1',
    description: '',
  },
]
const DEFAULT_IMAGES2 = [
  {
    id: '1',
    source: future_in_the_past01,
    thumbnail: thumb01,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '2',
    source: future_in_the_past02,
    thumbnail: thumb02,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '3',
    source: future_in_the_past03,
    thumbnail: thumb03,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '4',
    source: future_in_the_past04,
    thumbnail: thumb04,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '5',
    source: future_in_the_past05,
    thumbnail: thumb05,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '6',
    source: future_in_the_past06,
    thumbnail: thumb06,
    caption: 'Photo 6',
    description: '',
  },
  {
    id: '7',
    source: future_in_the_past07,
    thumbnail: thumb07,
    caption: 'Photo 7',
    description: '',
  },
  {
    id: '8',
    source: future_in_the_past08,
    thumbnail: thumb08,
    caption: 'Photo 1',
    description: '',
  },
  {
    id: '9',
    source: future_in_the_past09,
    thumbnail: thumb09,
    caption: 'Photo 2',
    description: '',
  },
  {
    id: '10',
    source: future_in_the_past10,
    thumbnail: thumb10,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '11',
    source: future_in_the_past11,
    thumbnail: thumb11,
    caption: 'Photo 11',
    description: '',
  },
  {
    id: '12',
    source: future_in_the_past12,
    thumbnail: thumb12,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '13',
    source: future_in_the_past13,
    thumbnail: thumb13,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '14',
    source: future_in_the_past14,
    thumbnail: thumb14,
    caption: 'Photo 14',
    description: '',
  },
  {
    id: '15',
    source: future_in_the_past15,
    thumbnail: thumb15,
    caption: 'Photo 15',
    description: '',
  },
  {
    id: '16',
    source: future_in_the_past16,
    thumbnail: thumb16,
    caption: 'Photo 16',
    description: '',
  },
  {
    id: '17',
    source: future_in_the_past17,
    thumbnail: thumb17,
    caption: 'Photo 3',
    description: '',
  },
  {
    id: '18',
    source: future_in_the_past18,
    thumbnail: thumb18,
    caption: 'Photo 4',
    description: '',
  },
  {
    id: '19',
    source: future_in_the_past19,
    thumbnail: thumb19,
    caption: 'Photo 5',
    description: '',
  },
  {
    id: '20',
    source: future_in_the_past20,
    thumbnail: thumb20,
    caption: 'Photo 7',
    description: '',
  },
  {
    id: '21',
    source: future_in_the_past21,
    thumbnail: thumb21,
    caption: 'Photo 1',
    description: '',
  },
]

const siteTitle = 'Onodera Ryo | future_in_the_past'

const Future02 = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      nonClass: '',
    }
  }
  toggleNon = () => {
    console.log("111");
    this.setState({
      nonClass: 'is-active',
    })
  }

  componentDidMount() {
    setTimeout(() => {
      this.toggleNon();
    },700);
  }

  render() {
    return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
      <div className="portrait">
      <Exhibitionnav>
      Future in the past 2016 ~ 2018
      </Exhibitionnav>
      <div class={`gall ${this.state.nonClass}`}>
      <MediaQuery query="(min-width: 767px)">
          <Gallery
                  images={DEFAULT_IMAGES.map(
                    ({ id, source, thumbnail, caption, description }) => ({
                      source,
                      thumbnail,
                      caption,
                      description,
                    })
                  )}
          />
        </MediaQuery>
        <MediaQuery query="(max-width: 767px)">
          <Gallery
                  images={DEFAULT_IMAGES2.map(
                    ({ id, source, thumbnail, caption, description }) => ({
                      source,
                      thumbnail,
                      caption,
                      description,
                    })
                  )}
          />
        </MediaQuery>
        </div>
      </div>
      </div>
    </Layout>
  )
  }
}

export default Future02